:root {
  --nav-bg: #dfdbe6;
  --footer-bg: #dfdbe6;
  --main-bg: #FFF629;
  --box-color: white;
  --control-grey: #333333;
  --modal-grey: #e7e7e7;
  --nav-size: 64px;
  --footer-size: 120px;
  --mobile-footer-size: 196px;
  
  --spacing-1: 16px;
  --spacing-2: 24px;
  --spacing-3: 32px;
  --spacing-4: 48px;
  --spacing-5: 64px;

  --small-input: 28px;
  --large-input: 200px;

  /* Toast Notifications*/
  --toastify-toast-width: 388px;
  --toastify-font-family: OurCustomFont;

  --toastify-text-color-light: #ffffff;
  --toastify-text-color-dark: #000000;
  
  --splash-color: #121212;
}

@font-face {
  font-family: Inter;
  font-weight: 1600;
  /* orig: 100 900*/
  font-display: swap;  --toastify-color-progress-dark: #bb86fc;
  font-style: oblique 0deg 10deg;
  src: url('assets/Inter.var.woff2') format("woff2");
}

@font-face {
  font-family: SK-Primo;
  src: url('./assets/skPrimo.ttf');
}

@font-face {
  font-family: VCR;
  src: url('./assets/VCR_OSD_MONO_1.001.ttf');
}

@font-face {
  font-family: IBM;
  src: url('./assets/IBMPlexMono-Regular.ttf');
}

@font-face {
  font-family: Technology;
  src: url('./assets/Technology.ttf');
}

@font-face {
  font-family: Bebas;
  src: url('./assets/Bebas-Regular.ttf');
}

@font-face {
  font-family: GeneralSans;
  src: url('./assets/GeneralSans-Regular.woff');
}

body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  font-family: OurCustomFont;
}
@media only screen and (max-width: 1200px) {
  body {
    position: fixed;
    height: -webkit-fill-available;
  }
  html {
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }
}

p {
  margin: 0;
}


nav {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  align-items: center;
  height: var(--nav-size);
  width: 100%;
}
#mainNav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  padding-right: 120px;
  margin-top: 20.47px;
  top: 0;
  left: 0;
  z-index: 3;
}
.logo {
  flex-grow: 1;
  font-family: SK-Primo;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  text-align: center;
  display: inherit;
  justify-content: center;
  color: #333333;
  padding-left: var(--spacing-2);
}
.leftLogo {
  margin-left: 30px;
}
.rightLogo {
  margin-right: 30px;
}
#topLeftImage {
  width: 138px;
}
#bottomLeftImage {
  width: 80px;
}
#bottomRightImage {
  width: 165px;
}
.controls {
  flex-shrink: 0;
}
.controlsLast {
  margin-right: 120px;
}
@media only screen and (max-width: 1200px) {
  .controlsLast {
    margin-right: 16px;
  }

  nav {
    margin: 0;
  }
}
.controlsButton {
  background: transparent;
  border: none;
  outline: none;
  display: block;
  cursor:pointer;
}
.menuButton {
  margin-right: 56px;
  font-family: GeneralSans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 160%;
  text-decoration: none;
  color: #000000;
}
@media only screen and (max-width: 1200px) {
  .menuButton {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
.menuButton:hover {
  text-decoration: underline;
  cursor: pointer;
}
.controlsButton img:hover {
  filter: brightness(2.5);
}
.connectButton {
  width: 90px;
  height: 30px;
  background: transparent;
  border: solid 2px gray;
  border-radius: 5px;
  margin-right: 7px;
}
.wallet {
  position: absolute;
  top: var(--nav-size);
  right: 120px;
  width: 480px;
  background-color: var(--box-color);
  box-shadow: 0 0 6px rgba(35, 173, 278, 1);
  z-index: 9998;
}
.upperWalletLine {
  margin-top: 16px;
  margin-bottom: 16px;
}
.accountBalanceBox {
  width: 80%;
  margin: auto;
  margin-top: 32px;
  margin-bottom: 16px;
  color: #4d00b4;
  text-align: center;
  background: #fbf9fe;
}
.accountBalanceBoxUpper {
  padding-top: 16px;
  padding-bottom: 4px;
  margin: 0px;
}
.accountBalanceBoxDowner {
  padding-bottom: 16px;
  margin: 0px;
  font-size: 22px;
  font-weight: bold;
}
.dot {
  height: 5px;
  width: 5px;
  margin-right: 6px;
  background-color: #000000;
  border-radius: 50%;
  display: inline-block;
}
.redDot {
  background-color: #EE0000;
}
.blueDot {
  background-color: #4d00b4;
}

#dropdownContainer {
  position: absolute;
  background: #fff;
  box-shadow: 0 1px 5px rgba(0,0,0,.25);
  border-radius: 1px;
  right: 400px;
  top: var(--nav-size);
  z-index: 5;
}
#dropdownContainer a {
  font-family: Inter;
  text-transform: uppercase;
  color: #333333;
  width: 180px;
  padding: 10px;
  margin: 0;
  border-bottom: solid #E0E0E0 1px;
}

.mobileDropdownContainer {
  position: absolute;
  background-color: #FFF629;
  top: var(--nav-size);
  left: 0;
  width: 100vw;
  height: calc(100vh - var(--nav-size));
  text-align: center;
}
.mobileDropdownContainer a {
  width: 100% !important;
  display: block;
}
.dropdownContainerSplashTwo {
  right: 420px !important;
}


footer {
  position: absolute;
  background: transparent;
  z-index: 2;
  display: flex;
  bottom: 0;
  left: 0;
  height: var(--footer-size);
  width: 100%;
  align-items: center;
}
footer ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  margin-left: 93.5px;
  margin-right: 93.5px;
  list-style: none;
}
footer ul li {
  margin-right: 107px;
}
footer ul li a {
  font-family: GeneralSans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 160%;
  color: #000000;
  text-decoration: none;
}
.footerSocialLi {
  margin-left: 12px;
  margin-right: 0;
}

#beatLogo {
  position: absolute;
  z-index: 2;
  bottom: 0;
  right: 64px;
}

main {
  background-color: var(--main-bg);
  height: 100vh;
  background-size: cover;
  overflow: hidden;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 1200px) {
  footer {
    position: relative;
    margin-left: 0;
    margin-right: 0;
    width: 100vw;
    height: var(--mobile-footer-size);
  }
  footer ul {
    flex-direction: column;
    width: 100%;
    justify-content: space-evenly;
    margin: 0;
    margin-bottom: 16px;
  }
  footer ul li {
    margin: 8px;
  }
  main {
    overflow-y: scroll;
    height: 100vh;
    width: 100vw;
  }
  #beatLogo {
    display: none;
  }
}
#adminLogo {
  display: block;
}
#adminMain {
  overflow: scroll;
  background: var(--main-bg);
}

.adminMain {
  padding: var(--spacing-5);
}

.connectWallet {
  padding-top: var(--spacing-5);
  height: 100vh;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
}
.mainWalletBadge {
  border: 1px solid #A673EF;
  background: transparent;
  border-radius: 3px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.08));
  font-family: GeneralSans;
  font-size: 16px;
  color: #8247E5;
  width: 204px;
  height: 52px;
}
.mainWalletBadge:hover {
  background: #BDBDBD;
  font-weight: bold;
  cursor: pointer;
}
.mainWalletBadgeTransparent {
  background: none;
  border: 2px solid var(--control-grey);
}
.mainWalletContainer {
  position: absolute;
  border: 1px solid #000000;
  font-family: GeneralSans;
  color: #000000;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.01em;
  background: #FFF629;
  mix-blend-mode: normal;
  border-radius: 3px;
  box-shadow: 2px 4px 50px rgba(0, 0, 0, 0.25);
  top: 60px;
  right: 78px;
  width: 204px;
  height: 109px;
  z-index: 9998;
}
@media only screen and (max-width: 1200px) {
  .mainWalletContainer {
    position: unset;
    right: 8px;
    margin: 16px;
    border: none;
    width: initial;
  }
}
#mainDisconnect {
  background-color: #000000;
  color: #FFFFFF;
  font-family: GeneralSans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  width: 172px;
  height: 34px;
  border: none;
}
@media only screen and (max-width: 1200px) {
  #mainDisconnect {
    width: 100%;
    margin-left: 16px;
    margin-right: 16px;
  }
}
#mainDisconnect:hover {
  font-weight: bold;
  cursor: pointer;
}
#mainWalletBalanceFlex {
  display: flex;
  align-items: center;
  padding: 16px;
}
#mainWalletBalanceFlex>* {
  margin-top: 0;
  margin-bottom: 0;
}
.mainWalletBalanceFlexNear {
  margin-left: 8px;
}
.walletFlexPlaceholder {
  flex-grow: 1;
}
#mainWalletNearLogo {
  margin-left: 5px;
}
#mainWalletDollarFlex {
  display: flex;
  margin-top: 8px;
  margin-bottom: 16px;
  letter-spacing: 0.15em;
}
#mainWalletDollarFlex>* {
  margin-top: 0;
  color: #4F4F4F;
  margin-bottom: 0;
}
#mainWalletButtonContainer {
  display: flex;
  justify-content: center;
}
.guestText {
  margin-top: 20px;
}


.walletFlexPlaceholder {
  flex-grow: 1;
}


#daorecordsHome {
  flex-grow: 1;
  color: #F5F6F7;
  text-decoration: none;
  padding-left: 64px;
}
.mainControlsLast {
  padding-right: 80px;
}
.walletBadge {
  background: transparent;
  border-radius: 3px;
  border: 1px solid #000000;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.08));
  font-family: GeneralSans;
  font-size: 20px;
  line-height: 160%;
  width: 204px;
  height: 52px;
}
.walletBadge:disabled {
  border: 1px solid #5C657D;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.08));
  color: #5C657D;
}
.walletBadge:hover {
  border: 1px solid #000000;
  box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.12), 0px 0px 0px 3px #DCD4FF;
}
.mainMenuButton {
  margin-right: 32px;
  font-family: GeneralSans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 160%;
  color: #F5F6F7;
  text-decoration: none;
}
.mainMenuButton:hover {
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .mainControlsLast {
    padding-right: 8px;
  }
  .walletBadge {
    width: 100%; 
  }
  .mainControlsLast {
    padding: 16px;
    padding-bottom: 0;
  }
}

#mynftsFooter {
  justify-content: flex-start;
  width: 100%;
  margin: 0;
}
#mynftsFooter ul {
  margin-left: 64px;
}
#mynftsFooter ul li {
  margin-right: 24px;
}

#mynftsBackground {
  background: black;
  position: absolute;
  height: 100vh;
  width: 100vw;
}

#mynftsGrid {
  display: grid;
  grid-auto-rows: auto 50px auto 50px;
  margin-top: var(--nav-size);
  margin-bottom: var(--footer-size);
  margin-left: 64px;
  margin-right: 64px;
  height: calc(100% - var(--nav-size) - var(--footer-size));
  background: unset;
  overflow: unset;
}
#mynftsTitle {
  font-family: GeneralSans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  color: #FFAD0D;
  /*margin-top: 97px;
  margin-bottom: 64px;*/
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}
#mynftsTitle img {
  width: 45px;
  height: 45px;
  margin-left: 16px;
  margin-right: 16px;
}
#mynftsFilterBar {
  margin-bottom: 48px;
  color: red;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding: 0  ;
}
.mynftsFilter {
  margin-right: 24px;
  padding-left: 16px;
  padding-right: 16px;
  height: 23px;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  align-items: center;
  caret-color: transparent !important;
  border-radius: 3px;
  border: 1px solid #DB8C09;
}
.mynftsFilter:hover {
  background-color: #DB8C09;
  color: #F2F2F2;
}
#mynftsList {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  list-style-type: none;
  padding: 0;
  margin-top: auto;
  margin-bottom: auto;
}
#mynftsList::-webkit-scrollbar {              /* scrollbar width */
  width: 7px;
}
.myNftsCard {
  margin-bottom: 32px;
}
#mynftsControls {
  display: flex;
  justify-content: space-between;
}
#mynftsPagination {
  display: flex;
  align-items: center;
  flex-direction: row;
  list-style-type: none;
}
.mynftsPageButton {
  background-color: #222222;
  border-radius: 10px;
  margin-left: 8px;
  font-family: GeneralSans;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #F2F2F2;
}
.mynftsPageButtonSelected {
  color: yellow;
  font-style: bold;
}
.mynftsWalletBadge {
  width: 219px;
  height: 42px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
  border: none;
  border-radius: 3px;
  background: #FFAD0D;
  font-family: GeneralSans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 160%;
  color: #000000;
  cursor: pointer;
}



#counter {
  font-family: Technology;
  color: #8fff00;
  padding-top: 1px;
  padding-bottom: 1px;
  width: 209px;
  border-radius: 2px;
  background-color: #6b7080;
  font-size: 16px;
  margin-left: 6px;
  margin-right: 6px;
}
.digitLabel {
  display: inline-block;
  margin-right: 12px;
}
.digit {
  display: inline-block;
  margin: 0 2px;
  height: 27px;
  width: 12px;
  background-color: #585a62;
}

.darkeningOverlay {
  position: absolute;
  background-color: rgba(0,0,0,0.5);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
.blurred {
  filter: blur(5px);
}
.keyInput {
  position: absolute;
  width: 100%;
  top: 46%;
  text-align: center;
  left: 0;
  font-family: OurCustomFont;
  z-index: 3;
}
.keyInput input {
  width: 50%;
  height: 48px;
}
.keyInput p {
  display: block;
  font-size: 22px;
  color: white;
  margin-bottom: var(--spacing-3);
}
.keyInput button {
  display: inline;
  margin-left: var(--spacing-3);
  width: 100px;
  height: 48px;
}

.title {
  margin: auto;
  margin-top: 48px;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  color: #000000;
}

.fieldName {
  display: block;
  margin-top: var(--spacing-4);
  margin-bottom: var(--spacing-2);
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.nftTitleInput {
  width: 99%;
  height: var(--small-input);
}

.descInput {
  width: 99%;
  height: var(--large-input);
}

.priceInput {
  width: 99%;
  height: 28px;
  background: white url('./assets/logo-black.svg') right no-repeat;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: none;
}

.royaltyList {
  list-style: none;
  padding: 0;
  margin: 0;
}
.royaltyElement {
  display: flex;
}
.royaltyList div {
  margin-right: 24px;
  flex-grow: 1;
}
.royaltyList div:last-child {
  flex-grow: 0;
  margin-right: 0;
}
.royaltyList div input {
  width: 100%;
  height: 28px;
}
.royaltyList div label {
  margin-bottom: 16px;
  margin-top: 16px;
}
.placeholderLabel {
  display: block;
  visibility: hidden;
}
.royaltyButton {
  border: none;
  background: transparent;
  display: inline;
  font-family: IBM;
  font-size: 40px;
  margin-left: 16px;
}
.smallRoyaltyLabel {
  display: block;
  font-size: 16px;
}


.previewBox {
  display: grid;
  padding: var(--spacing-3);
  box-shadow: 0 0 6px rgba(35, 173, 278, 1);
  background-color: var(--box-color);
}
.previewImage {
  width: 100%;
}
.previewBoxTitle {
  margin-top: var(--spacing-3);
  margin-bottom: var(--spacing-2);
}
.price {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  height: var(--spacing-4);
}
.priceP {
  font-size: 22px;
  font-weight: bold;
  margin-right: 10px;
}
.previewBoxItem {
  grid-column-start: 1;
  grid-column-end: 4;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1200px) {
  .previewBoxItem {
    margin-top: var(--spacing-2);
    margin-bottom: var(--spacing-2);
  }
}

.imageDropZone {
  width: 99%;
  height: var(--spacing-5);
  border: solid 2px #009aff;
  background-color: #f0f9ff;
  border-style: dashed;
  border-radius: 2px;
  text-align: center;
}
.imageDropZoneActive {
  background-color: white;
}
.imageDropZone img {
  padding: 20px;
}
.smallUploader {
  width: max-content;
  height: 28px;
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 5px;
  border: solid 2px #009aff;
  background-color: #f0f9ff;
  border-style: dashed;
  border-radius: 27px;
  text-align: center;
}
.smallUploader button {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.infoDiv {
  color: #bdbdbf;
  display: flex;
  margin-bottom: -16px;
}
.infoDiv p {
  margin-left: 8px;
}

.mainButton {
  margin-top: 80px;
  border: none;
  padding: 11.5px var(--spacing-3);
  color: white;
  background: #333333;
  border-radius: 3px;
}
.mainButton:hover {
  background: #828282;
  cursor: pointer;
}
.mainButton:disabled {
  background: #C4C4C4;
}
#disconnectButton {
  margin-top: 0px;
  margin-bottom: 32px;
}
.buttonContainer {
  text-align: center;
}

.adminFlexBox {
  display: flex;
}
.nft-details {
  margin-right: 64px;
  width: 70%;
}
.preview {
  position: static;
  width: 30%;
}

h1 {
  font-family: GeneralSans;
  font-size: 40px;
  /*margin-top: calc(var(--nav-size) + 72px);*/
  margin-top: 60px;
  margin-bottom: 40px;
  text-align: center;
}

#listContainer {
  margin-bottom: calc(var(--footer-size) + 72px);
  margin-left: 120px;
  margin-right: 120px;
  padding: 0;
}
#listContainer li {
  list-style: none;
  display: inline-block;
  margin: 20px;  
}
.nftCard {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  background: #323232;
  border-radius: 10px;
  padding: 24px;
  border: none;
  width: 301px;
}
.nftCard img {
  max-width: 100%;
  border-radius: 6px;
}
.nftCardInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.nftCardInfoArtistList {
  font-size: 10px;
  /* line-height: 11px;*/
}
.nftCardInfoTitle {
  font-family: GeneralSans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 160%;
  color: #FFFFFF;
  margin-top: 16px;
  margin-bottom: 0px;
}
.nftCardInfoBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 19px;
  font-family: GeneralSans;
}
.nftCardGen {
  flex-grow: 1;
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  text-align: left;
  color: #FFC749;
}
.nftCardNearPrice {
  margin: 0;  
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 160%;
  margin-right: 8px;
  color: #FFC749;  
}
.nftCardButtons {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 22px;
  font-family: GeneralSans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
}
.nftCardSecondaryButton {
  border: 1px solid #FFAD0D;
  background: transparent;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.08));
  border-radius: 3px;  
  height: 34px;
  width: 118px;
  margin-right: 17px;
  color: #FFAD0D;
}
.nftCardPrimaryButton {
  background: #FFAD0D;
  border: none;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
  border-radius: 3px;  
  height: 34px;
  width: 118px;
  color: #000000;
}
.nftCardInfoDollarPrice {
  align-self: flex-end;
  font-size: 11px;
  margin: 0;
  color: #4F4F4F;
  /* line-height: 11px;*/
}
@media only screen and (max-width: 1200px) {
  #listContainer {
    margin-left: 0;
    margin-right: 0;
    text-align: center;
  }
}


.loadingLabel {
  font-family: VCR;
  text-transform: uppercase;
}

#nftButtonsList {
  position: absolute;
  z-index: 2;
  top: 110px;
  left: 30px;
}
.nftButton {
  display: block;
  font-family: IBM;
  text-transform: uppercase;
  width: 80px;
  height: 29px;
  border: none;
  margin-top: 20px;
  background-color: rgba(43, 58, 111, 0.2);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  border-radius: 4px;

  /*animation-name: labelFadeOut;*/
  animation-timing-function: ease;
  animation-duration: 1s;
  transform-origin: left;
  /*transition-duration: 1s;
  transform-origin: left;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;*/

  /*background-color: #333333;*/
}
.nftButton:hover {
  background: url('./assets/cd.png') no-repeat;
  background-size: 32px;
  padding-left: 48px;
  text-align: left;
  background-position: 8px 8px;
  /*transform: scale(3, 1.6);*/
  /*animation-name: labelFadeIn;*/
  animation-timing-function: ease;
  animation-duration: 1s;
  transform-origin: left;
  width: 161px;
  height: 50px;
}
@keyframes labelFadeIn {
  0% { transform: scale(0.65); }
  
  100% { transform: scale(1); }
}
@keyframes labelFadeOut {
  0% { transform: scale(1.33); }
  
  100% { transform: scale(1); }
}

.nftButtonGlobe {
  color: white;
}


#backgroundContainer {
  z-index: 2;
  background: url('./assets/sampleBackground.jpg');
  height: 100%
}
#trackList {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 70%;
}
.nftCircle {
  position: absolute;
  z-index: 3;
  text-align: center;
  line-height: 180px;
  color: yellow;
  border-radius: 250px;
  border: 2px solid red;
  width: 200px;
  height: 200px;
  margin-right: 32px;
  padding: 5px;
}


#globeContainer {
  z-index: 2;
}

.temporaryCARD {
  width: 300px;
}
.dropdownContainer {
  position: absolute;
  top: var(--nav-size);
  right: var(--spacing-3);
  width: 399px;
  background-color: var(--box-color);
  box-shadow: 0 0 6px rgba(35, 173, 278, 1);
  z-index: 9998;
}
#wallet {
  text-align: center;
}

.nftDetailsModal {
  position: absolute;
  display: grid;
  grid-template-columns: 70% 30%;
  top: 0;
  left: 0;
  text-align: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
  font-family: GeneralSans;
  z-index: 50;
}
#nftDetailsModalPicture {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: white;
}
#nftDetailsModalPicture img {
  max-width: 80%;
  max-height: 80%;
}
#nftDetailsModalRightSide {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #f8fcfc;
  height: 100vh;
  padding: 40px;
  padding-top: 16px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing:    border-box;
  -ms-box-sizing:     border-box;
  -o-box-sizing:      border-box;
  box-sizing:         border-box;
}
#nftDetailsModalClose {
  align-self: flex-end;
  background: transparent;
  border: none;
  margin-bottom: 8px;
}
.nftDetailsModalAudioPlayer {
  display: flex;
  align-items: center;
}
#nftDetailsModalTitleAndGen {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  color: var(--splash-color);
  display: flex;
  flex-direction: row;
}
#nftDetailsModalTitleAndGen p {
  margin-right: 16px;
}
#nftDetailsModalArtistList {
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #4F4F4F;
  margin-top: 16px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-left: 0;
}
.nftCardImageContainer {
  text-align: right;
  margin-bottom: -38px;
}
.nftCardPlay {
  position: relative;
  bottom: 56px;
  right: 16px;
}
#nftDetailsModalArtistList li {
  list-style: none;
}
.nftCardArtistList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-left: 0;
  margin: 0;
}
.nftCardArtistListElement {
  list-style: none;
  margin: 0 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px !important;
}
.nftCardArtistListElement img {
  width: 32px;
  height: 32px;
  border-radius: 500px;
  margin-right: 4px;
}
.nftCardArtistListElement p {
  font-family: GeneralSans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  color: #FAFAFB;
  margin: 0;
}
.nftDetailsModalMenuLine {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 8px;
}
.nftDetailsModalMenuLine button {
  border: none;
  background: transparent;
  font-size: 12px;
  line-height: 14px;
  color: var(--splash-color);
  text-align: center;
  flex-grow: 1;
}
.nftDetailsModalMenuLine button:last-child {
  text-align: right;
}
.nftDetailsModalMenuLine button:first-child {
  text-align: left;
}
.nftDetailsModalMenuSelected {
  text-decoration: underline;
}
#nftDetailsModalOwnerBox {
  display: flex;
  flex-direction: row;
  background-color: white;
  width: 93%;
  padding: 16px;
  border-radius: 7px;
}
#nftDetailsModalOwnerBox p {
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: var(--splash-color);
  padding: 0;
  margin: 0;
}
#nftDetailsModalOwnerBox > p:first-child {
  font-weight: bold;
  flex-grow: 1;
  text-align: left;
}
#nftDetailsModalAudio {
  width: 100%;
  grid-area: audio;
  margin-top: 20px;
  padding-right: 25px;
  
  display: flex;
  align-items: center;
}
#nftDetailsModalButtons {
  display: flex;
  align-items: flex-end;
  align-self: flex-end;
  flex-grow: 1;
  margin-top: auto;
}
#nftDetailsModalButtons button {
  height: 38px;
  border-radius: 50px;
  font-size: 16px;
  border: 2px solid var(--splash-color);
  background: transparent;
  padding-left: 16px;
  padding-right: 16px;
  text-transform: uppercase;
  line-height: 18px;
  color: var(--splash-color);
}
#nftDetailsModalButtons button:hover {
  font-weight: bold;
  cursor: pointer;
}
.nftDetailsModalSwitchableBox {
  background: white;
  width: 93%;
  height: 200px;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  color: var(--splash-color);
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 7px;
}

@media only screen and (max-width: 1200px) {
  .nftDetailsModal {
    width: 100vw;
    grid-template-columns: none;
    grid-template-rows: auto auto;
    grid-gap: 0;
    height: 100vh;
    overflow-y: scroll;
  }
  #nftDetailsModalPicture {
    margin: 0;
    margin-top: 64px;
  }
  #nftDetailsModalPicture img {
    margin: 32px;
    padding: 32px;
    width: 70vw;
    max-width: none;
    max-height: none;
  }
  #nftDetailsModalRightSide {
    padding: 10px;
    width: 100vw;
    height: auto;
  }
}

#musicPlayer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 600px;
  height: 150px;
  bottom: 8px;
  right: 8px;
  background: yellow;
  color: red;
  border-radius: 10px;
  text-align: center;
  z-index: 500;
}
#musicPlayerSongName {
  margin: 0;
  padding: 0;
}
.musicControlsButton {
  flex-shrink: 1;
  background: transparent;
  border: none;
  outline: none;
  margin-bottom: -5px;
  cursor: pointer;
}
.musicControlsSlider {
  flex-grow: 1;
  height: 6px;
  /*background-color: var(--control-grey);*/
  background-color: transparent;
  border: 1px solid #000000;
}
.musicControlsSliderWhite {
  background-color: #F2F2F2;
}
.musicControlsSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 90px;
  height: 8px;
  border: 20px solid #000000;
  border-top: none;
  border-bottom: none;
  background-color: #000000;
  border-radius: 0;  
  cursor: pointer;
}
.musicControlsSliderWhite::-webkit-slider-thumb {
  border: 12px solid white;
  border-top: none;
  border-bottom: none;
  background-color: white;
}
.musicControlsSlider::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 8px;
  width: 20x;
  border: 40px solid #000000;
  border-top: none;
  border-bottom: none;
  background-color: #000000;
  margin-top: -5px;
  border-radius: 0;
}
.musicControlsSliderWhite::-moz-range-thumb {
  border: 12px solid white;
  border-top: none;
  border-bottom: none;
  background-color: white;
}
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  width: 99%;
}


.buyModal {
  position: absolute;
  display: grid;
  grid-template-areas: 
    'bar'
    'content';
  top: 100px;
  left: 16%;
  width: 1000px;
  /*background-color: rgba(255, 255, 255, 0.90);*/
  border: 2px solid var(--control-grey);
  box-shadow: 0px 1px 4px rgba(0,0,0, 0.2);
  background-color: var(--modal-grey);
  z-index: 3;
}
#buyModalBar {
  grid-area: bar;
  font-family: VCR;
  font-size: 16px;
  height: 38px;
  border-bottom: 1px solid #333;
  
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
#buyModalBar p {
  margin: -10px 0;
  flex-grow: 1;
  text-transform: uppercase;
  text-align: center;
}
#buyModalBar button {
  margin: 0px 2px;
  border: none;
  background: transparent;
  font-family: VCR;
  font-size: 16px;
}
#buyModalContent {
  grid-area: content;

  padding: var(--spacing-3);
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  grid-template-areas: 
  'picture right'
  'audio buttons';
}
#buyModalRightSide {
  grid-area: right;

  display: flex;
  flex-direction: column;
}
.buyModalRightSideContent {
  padding: var(--spacing-1);
  font-family: IBM;
  font-size: 14px;
  background-color: var(--modal-grey);
  border: solid 1px var(--control-grey);
  margin-bottom: 20px;
  flex-grow: 1;
}
.buyModalRightSideGenBox {
  font-family: IBM;
  font-weight: bolder;
  height: 45px;
  font-size: 14px;
  text-transform: uppercase;
  border: solid 1px var(--control-grey);
  display: flex;
  align-items: center;
  padding-left: 12px;
}
.buyModalRightSideInput {
  flex-grow: 1;
  margin-right: 20px;
  height: 34px;
  padding-left: 12px;
  background: #efefef;
}
.buyModalMenuLine {
  height: 10px;
  margin-bottom: 20px;
  border: none;
  
  display: flex;
}
.buyModalMenuButton {
  font-family: IBM;
  font-size: 12px;
  text-transform: uppercase;
  border: none;
  background: transparent;
  flex-grow: 1;
}
.buyModalMenuSelected {
  text-decoration: underline;
}
#buyModalAudio {
  grid-area: audio;
  margin-top: 20px;
  padding-right: 25px;
  
  display: flex;
  align-items: center;
}
#buyModalButton {
  grid-area: buttons;
  font-family: VCR;
  font-size: 16px;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.buyModalRightOwnerDiv {
  padding: 8px;
  border: 1px solid var(--control-grey);
  margin-bottom: 20px;
  display: flex;
}
.buyModalRightAccount {
  font-weight: bolder;
  text-transform: uppercase;
  margin: 0;
  flex-grow: 1;
}
.buyModalRightGen {
  font-weight: bolder;
  margin: 0;
  text-transform: uppercase;
}


#popupWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 4;
}
.darkened {
  background: rgba(17, 17, 17, 0.4);
}
#transferPopup {
  position: absolute;
  padding: 32px;
  top: calc(50% - (460px / 2));
  left: calc(50% - (400px / 2));
  background: #F8FDFD;
  width: 400px;
  min-height: 260px;
  box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  z-index: 5;
}
.transferPopupTitle {
  font-family: Inter;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #333333;
  margin-top: 0;
  margin-bottom: 32px;
}
.transferPopupNormalText {
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin: 0;
  padding: 0;
}
.transferPopupInline {
  display: inline;
}
.transferPopupBoldText {
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #121212;
  margin: 0;
  padding: 0;
}
.transferPopupInput {
  box-sizing: border-box;
  width: 336px;
  height: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
}
.transferPopupButton {  
  width: 125px;
  height: 38px;
  background: #121212;
  border: none;
  border-radius: 50px;
  font-family: GeneralSans;
  text-transform: uppercase;
  font-weight: 450;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #FFFFFF;
  margin-top: 32px;
}
.transferPopupButton:hover {
  cursor: pointer;
  background: #FFFFFF;
  border: 2px solid #121212;
  color: #121212;
}
@media only screen and (max-width: 1200px) {
  #transferPopup {
    left: 0;
    bottom: 200px;
    top: auto;
    width: calc(100% - 64px);
  }
  #transferPopupWrapper {
    height: 110vh;
    overflow: hidden;
  }
}
.scroll {
  overflow-y: scroll;
}


.hamburgerElement {
  padding: 16px;
  font-family: GeneralSans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  color: #F2F2F2;
}
.InterMenu {
  font-family: Inter;
  color: #333333;
}
.F2White {
  color: #F2F2F2;
}
.InterButton {
  background-color: #333333;
  font-family: Inter;
  color: #F2F2F2;
}
.hamburgerElement:hover {
  text-decoration: underline;
}

.hamburgerIcon {
  margin-left: 16px;
  background: none;
  border: none;
}

.mainLogo {
  flex-grow: 1;
  padding-left: 68px;
}
.daoLogo {
  flex-grow: 1;
  padding-left: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}
.daoLogo p {
  margin-left: 4px;
  color: var(--control-grey);
}

@media only screen and (max-width: 1200px) {
  #mainNav {
    justify-content: space-between;
    padding-right: 0;
    width: 100vw;
  }
  .mainLogo {
    padding: 0;
    padding-left: 16px;
  }
  .hamburgerIcon {
    margin-right: 24px;
  }
}



/** Default Landing Grid*/
#landingGrid {
  margin-top: 143px;
  margin-left: 112px;
  margin-right: 113px;
  height: 60vh;
  display: grid;
  grid-template-columns: 24vw auto;
  grid-template-rows: 24vw 92px;
  grid-template-areas:  "picture details"
                        "player actions";
}

#mixtapeGrid {
  margin-top: 143px;
  margin-left: 112px;
  margin-right: 113px;
  height: 60vh;
  display: grid;
  grid-template-columns: 24vw auto 24vw;
  grid-template-rows: 24vw 92px;
  grid-template-areas:  "picture details songs"
                        "player actions songs";
}


#pictureBox {
  grid-area: picture;
}
#detailsBox {
  grid-area: details;
  margin-left: 40px;
  padding: 24px;
  border: 3px solid #000000; 
  backdrop-filter: blur(15px);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
}
#playerBox {
  grid-area: player;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
}
#actionsBox {
  grid-area: actions;
  margin-left: 40px;
  margin-top: 16px;
  align-self: end;
}
#songMenuBox {
  grid-area: songs;
  margin-left: 40px;
  padding: 24px;
  padding-right: 4px;
  border-radius: 20px;  
  background: #000000;
}
#songMenuBoxDropdownButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background: transparent;
  border: 3px solid #000000;
  border-radius: 12px;
  width: 100%;
  font-family: GeneralSans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #000000;
  margin-bottom: 24px;
}

.detailsBoxPlaceholder {
  flex-grow: 1;
}

#detailsBoxScrollContainer {
  margin-top: 8px;
  margin-bottom: 8px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: black transparent;
}
#detailsBoxScrollContainer::-webkit-scrollbar {
  width: 4px; 
}
#detailsBoxScrollContainer::-webkit-scrollbar-track {
  background-color: transparent;
}
#detailsBoxScrollContainer::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
}

#picture {
  width: 100%;
  box-shadow: 4px 4px 60px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}

#title {
  font-family: GeneralSans;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  background: #000000;
  padding: 16px 24px;
  margin: 0;
  text-transform: uppercase;
  border-radius: 12px;
}

.mobileTitle {
  font-size: 20px !important;
  line-height: 27px !important;
  text-align: center !important;
  margin-bottom: 24px !important;

  grid-area: title;
}

#description {
  /*max-height: 27vh;*/
  font-family: GeneralSans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: justify;
  letter-spacing: 0.02em;
  color: #000000;
  margin-left: 16px;
  margin-right: 16px;
  /*overflow-y: scroll;*/
  overflow-x: visible;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#songMenuTitle {
  font-family: GeneralSans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #FFFFFF;
  margin: 0;
  margin-bottom: 40px;
}
#songMenuList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  height: 100%;
  overflow-y: scroll;
  padding-right: 20px;
  scrollbar-width: thin;
  scrollbar-color: #FEF513 transparent;
}
#songMenuList::-webkit-scrollbar {
  width: 4px; 
}
#songMenuList::-webkit-scrollbar-track {
  background-color: transparent;
}
#songMenuList::-webkit-scrollbar-thumb {
  background: #FEF513;
  max-height: 50px;
  height: 50px;
  border-radius: 10px;
}
.songMenuElement {
  font-family: GeneralSans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  padding: 16px;
  border-radius: 14px;
}
.songMenuElementSelected {
  background: #FEF513;
  color: #000000;
}


#smallInfoBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border: 3px solid #000000;
  border-radius: 12px;
}
.noSpaceTop {
  margin-top: 0 !important;
}
.smallInfoBoxElement {
  height: 60px;
}


.smallInfoElement {
  font-family: GeneralSans;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.01em;
  margin-right: 16px;
}
.infoElementSecond {
  flex-grow: 1;
}
.infoElementThird {
  margin-right: var(--spacing-3);
}
.infoElementFourth {
  margin-right: 0;
}
.infoElementBreak {
  flex-basis: 100%;
}
.smallInfoBoxValue {
  font-family: GeneralSans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  letter-spacing: 0.01em;
  color: #000000;
  margin-right: 8px;
}

.artistListElement {
  display: flex;
  flex-direction: row;
}

#artistList {
  list-style-type: none;
  padding: 0;
  margin-top: 0;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 8px;
}
.artistListElementFirst {
  flex-grow: 1;
  margin: 0;
  font-family: GeneralSans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #000000;
}
.artistListElementIcon {
  margin-left: var(--spacing-1);
}


#buy {
  display: flex;
  justify-content: flex-end;
}
#buy button {
  background: #000000;
  color: #FFFFFF;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  width: 115px;
  height: 52px;
  padding: 10px 16px;
  font-family: GeneralSans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  white-space: nowrap;
  line-height: 160%;
}
#buy button:hover {
  box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.12), 0px 0px 0px 3px #D1D1D2;
  cursor: pointer;
}
#buy button:disabled {
  background: #5C657D;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);  
}

.splash-eq {
  position: absolute;
  left: 000px;
}
@media only screen and (max-width: 1200px) {
  #landingGrid {
    margin: 16px;
    padding: 0;
    margin-top: var(--nav-size);
    height: auto;
    grid-template-columns: 100%;
    grid-template-rows: auto  ;
    grid-template-areas:  "title"
                          "picture"
                          "player"
                          "details"
                          "actions";
  }

  #mixtapeGrid {
    margin: 16px;
    padding: 0;
    margin-top: var(--nav-size);
    height: auto;
    grid-template-columns: 100%;
    grid-template-rows: auto  ;
    grid-template-areas:  "title"
                          "songs"
                          "picture"
                          "player"
                          "details"
                          "actions";
  }

  #detailsBox {
    margin: 0;
    padding: 16px;
  }

  #playerBox {
    margin-top: 8px;
    margin-bottom: 24px;
  }

  #detailsBoxScrollContainer {
    margin: 0;
    overflow-y: visible;
    height: 100%;
  }

  #artistList {
    margin: 0;
    margin-top: 4px;
  }
  .artistListElement {
    margin-bottom: 4px;
  }

  .smallInfoElement {
    font-size: 16px;
    line-height: 22px;
  }
  
  .smallInfoBoxValue {
    font-size: 20px;
    line-height: 27px;
  }

  #description {
    font-size: 14px;
    line-height: 24px;
    text-align: justify;
    margin: 0;
    margin-bottom: 51px;
  }

  #songMenuBox {
    background-color: transparent;
    margin: 0;
    padding: 0;
  }
  #songMenuList {
    position: absolute;
    margin: 16px;
    padding: 8px;
    margin-top: -20px;
    left: 0;
    width: calc(100% - 48px);
    height: max-content;
    background: #FFF629;
    box-shadow: 2px 4px 50px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    z-index: 3;
  }
  .songMenuElement {
    font-family: GeneralSans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.01em;
    color: #000000;
  }
  .songMenuElementSelected {
    border: 3px solid #000000;
    background-color: transparent;
    border-radius: 12px;
  }
  
  #actionsBox {
    margin: 0;
    padding: 0;
  }

  #buy {
    margin-top: 24px;
    margin-bottom: 48px;
    justify-content: center;
  }

  #buy button {
    font-size: 18px;
  }
}






/* a11y*/
.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/* Toast messages*/
/*  This rule has to be upper then success */
.Toastify__toast-theme--light {
  background-color: #4f2e6d;
  width: 388px;
  border-radius: 0px;
}
.Toastify__toast--success {
  background-color: #1c7c20;
}
.Toastify__toast--error {
  background-color: #ba0f76;
}
.messageTitle {
  font-weight: 600;
  font-size: 17px;
  font-style: bold;
  margin-bottom: var(--spacing-1);
}
.messageDesc {
  font-weight: 600;
  font-size: 14px;
}
.toastMargin {
  margin-left: var(--spacing-2);
}

.dropdownTitle {
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-1);
  text-align: center;
}

.dropdownLine {
  /*color: #828282;*/
  width: 80%;
  border: none;
  border-top: 1px #f2f2f2 solid;
  height: 1px;
}

.notificationList {
  padding: 0;
  margin: var(--spacing-2);
  margin-bottom: var(--spacing-1);
}
.notificationList li {
  list-style: none;
  margin: 0;
	padding: 0 0 0 60px;
  background-repeat: no-repeat;
	background-position: left center;
	background-size: 40px;
  margin-bottom: var(--spacing-1);
}
.successNotification {
  background-image: url('assets/ok-256.png');
}
.errorNotification {
  background-image: url('assets/ok-256.png');
}
.infoNotification {
  background-image: url('assets/ok-256.png');
}


.testClass {
  /* this is where toastify would append this class*/
  font-style: italic;
}

/** Audio Player*/
.Player{
  display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    position: relative;
    height: 360px;
    width: 360px;
}

#Player-canvas{
  position: absolute;
  width: 900px;
  height: 900px;
  display: flex;
  align-self: center;
  z-index: 2;
}

.Player .controls{
  display: grid;
  grid-template: "prev play next";
  justify-content: center;
  position: relative;
  z-index: 3;
}

.Player .controls .pause-play-song{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: rgba(97,218,251,0.8) 3px solid;
    border-radius: 50%;
    padding: 0.5rem;
    width: 104px;
    height: 104px;
}

.Player .song-footer{
  color: rgba(97,218,251,0.8);
  position: absolute;
  z-index: 2;
  bottom: 2rem;
  width: 100%;
}

#playerButtons {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.playerButton {
  background: none;
  border: none;
  cursor: pointer;
}

#playerTime {
  font-family: GeneralSans;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  align-self: flex-end;
}

.splash-eq {
  top: calc(50% - 250px + var(--nav-size));
  left: calc((100vw / 4) - 250px + 120px);
}

.player-loader{
  max-width: 100%;
  border-radius: inherit;
}
@media only screen and (max-width: 1200px) {
  #Player-canvas {
    width: 100vw;
    height: 100vw;
  }
  .splash-eq {
    top: calc(var(--nav-size) + 0);
    left: 0;
  }
}


/** Line Eq*/

#lineVisualizer {
  z-index: 1;
  height: calc(100vh - 60vh  );
  width: 100vw;
  margin-top: -100px;
  pointer-events: none; 
}


@media only screen and (max-width: 1200px) { 
  #lineEqControls {
    width: 100vw;
    height: auto;
    
    left: 0px;
    top: 0px;
    margin-top: var(--spacing-3);
    margin-bottom: var(--spacing-3);
    text-align: center;
  }
}